<template>
  <div class="component-fullwidth">
    <!-- <div id="dashboard" v-if="!loading"> -->
      <div class="flex-container flex-full no-padding no-margin" v-if="!loading">
        <div class="elem-flex">
          <div class="title-page">
            {{ $t("page_title_dashboard") }}
          </div>
        </div>
      </div>
      <div class="inner-container" v-if="!loading">
        <div class="flex-container flex-full">
          <!-- upcoming -->
          <div class="elem-flex">
            <div class="subtitle-page">
              <span v-html='$t("dash_upcoming_subtitle")'></span>
            </div>
            <div id="dash-upcoming-splide"
              v-if="events.length > 0"
              class=""
            >
              <splide class="list-splide-upcoming splidepadding"
                v-if="events.length <= 3"
                :options="optionsUpcomingSolo"
              >
                <splide-slide
                  class="elem-splide-upcoming box-shadow radius-small "
                  v-for="event in events"
                  :key="event._id"
                >
                  <div
                    class="link"
                    v-if="event.processRef"
                    @click="viewProcess(event)"
                  ></div>
                  <div class="text">
                    <div class="title-upcoming">
                      {{event.title}}
                    </div>
                  </div>
                  <div class="hours">
                    <span>{{event.date.start | moment('HH:mm a')}}</span>
                    <span class="separ"></span>
                    <span>{{event.date.end | moment('HH:mm a')}}</span>
                  </div>
                </splide-slide>
              </splide>
              <splide class="list-splide-upcoming splidepadding"
                v-else
                :options="optionsUpcomingMultiple"
              >
                <splide-slide
                  class="elem-splide-upcoming box-shadow radius-small"
                  v-for="event in events"
                  :key="event._id"
                >
                  <div
                    class="link"
                    v-if="event.processRef"
                    @click="viewProcess(event)"
                  ></div>
                  <div class="text">
                    <div class="title-upcoming">
                      {{event.title}}
                    </div>
                  </div>
                  <div class="hours">
                    <span>{{event.date.start | moment('HH:mm a')}}</span>
                    <span class="separ"></span>
                    <span>{{event.date.end | moment('HH:mm a')}}</span>
                  </div>
                </splide-slide>
              </splide>
            </div>
            <div id="dash-upcoming-splide"
              v-else
              class=""
            >
              <splide class="list-splide-upcoming splidepadding" :options="optionsUpcomingSolo">
                <splide-slide class="elem-splide-upcoming box-shadow radius-small no-coming">
                  {{ $t("upcoming_no_event") }}
                </splide-slide>
              </splide>
            </div>
          </div>
          <!-- current application -->
          <div class="elem-flex">
            <div class="subtitle-page no-margin">
              Active Applications ({{offers.length}})
              <!-- {{offersOrdered}} -->
            </div>
            <div id="dash-application-splide"
              class=""
            >
              <splide class="list-splide-application dash-splide splidepadding"
                v-if="offersOrdered.length === 1"
                :options="optionsOfferSolo"
              >
                <splide-slide
                  class="elem-splide-application"
                  v-for="offer in offersOrdered"
                  :key="offer._id"
                >
                  <job-elem-list
                    :elem="offer.offer"
                    :redirection="false"
                    class="box-shadow radius-small jobDash"
                    @click.native="(event) => openProcessModal(event, offer)"
                  >
                    <process-progress

                      v-if="offer.hasOwnProperty('application')
                        && offer.application.status === 'process'"

                      :steps="offer.application.process"
                    />
                  </job-elem-list>
                </splide-slide>
              </splide>
              <splide class="list-splide-application dash-splide splidepadding"
                v-if="offers.length > 1"
                :options="optionsOfferMultiple"
                :slides="offers"
              >
                <splide-slide
                  class="elem-splide-application"
                  v-for="offer in offers"
                  :key="offer._id"
                >

                  <job-elem-list
                    :elem="offer.offer"
                    :redirection="false"
                    class="box-shadow radius-small jobDash"
                    @click.native="(event) => openProcessModal(event, offer)"
                  >
                    <process-progress
                      v-if="offer.hasOwnProperty('application')
                      && offer.application.status === 'process'"
                      :steps="offer.application.process"
                    />
                    <div class="inner-process" v-else>
                      <div class="step-cont"></div>
                    </div>
                  </job-elem-list>
                </splide-slide>
              </splide>
              <splide class="list-splide-upcoming splidepadding" :options="optionsUpcomingSolo"
                v-if="!offers.length"
              >
                <splide-slide class="elem-splide-upcoming box-shadow radius-small no-app">
                  No Current Application<br><br>
                  <span>
                    You can find job offer through search field
                  </span>
                </splide-slide>
              </splide>
            </div>

          </div>
          <!-- last news feed -->
          <div class="elem-flex">
            <div class="subtitle-page no-margin wother">
              {{ $t("dash_lastnewsfeed_subtitle") }}
              <div class="other">
                <router-link to="/feed">
                  All Feed
                </router-link>
              </div>
            </div>
            <div class="dash-feed-splide"
              v-if="feedPosts.length > 0"
            >
              <splide class="list-splide-feed dash-splide splidepadding"
                v-if="feedPosts.length <= 3"
                :options="optionsFeedSolo"
              >
                <splide-slide
                  class="elem-splide-feed box-shadow radius-small"
                  v-for="postFeed in feedPosts"
                  :key="postFeed._id"
                >
                  <post-view :post="postFeed" />
                </splide-slide>
              </splide>
              <splide class="list-splide-feed dash-splide splidepadding"
                v-else
                :options="optionsFeedMultiple"
              >
                <splide-slide
                  class="elem-splide-feed box-shadow radius-small"
                  v-for="postFeed in feedPosts"
                  :key="postFeed._id"
                >
                  <post-view :post="postFeed" />
                </splide-slide>
              </splide>
            </div>
          </div>
        </div>
      </div>
    <!-- </div> -->
    <loading v-else />
  </div>
</template>

<script>

import { mapState } from 'vuex';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import Loading from '@/components/utilityPartials/_loading.vue';
import PostView from '@/components/UniversalComponents/Feed/_post.vue';
import JobElemList from '@/components/UniversalComponents/Offer/JobElemList.vue';
import ProcessProgress from '@/components/EmployeeComponents/JobManagement/processPartials/processProgress.vue';
import OfferProgress from '@/components/TalentComponents/offer/offerProgress.vue';
import ApplicantProcess from '@/components/EmployeeComponents/JobManagement/processPartials/applicantProcess.vue';

export default {
  name: 'TalentDashboard',
  components: {
    Loading,
    PostView,
    JobElemList,
    ProcessProgress,
    Splide,
    SplideSlide,
  },
  computed: {
    ...mapState('talent', {
      talentInfo: (state) => state.talentInfo,
      offers: (state) => state.offers,
      offersOrdered: (state) => {
        console.log('waa');
        return state.offers.sort(
          (a, b) => new Date(b.application.createdAt) - new Date(a.application.createdAt),
        );
      },
      offersRetrieved: (state) => state.offersRetrieved,
      loading: (state) => state.loadingInfo,
    }),
    ...mapState('user', ['_id']),
    feedPosts() {
      const tempFeed = this.$store.state.feed.myFeed.feedPosts;
      return tempFeed.slice(0, 9);
    },
    events() {
      const upcomingevents = this.$store.getters['calendar/upcoming']();
      return upcomingevents;
    },
  },
  data() {
    return {
      optionsUpcomingSolo: {
        perPage: 3,
        gap: '2vh',
        padding: '1vh',
        rewind: true,
        pagination: false,
        arrows: false,
        drag: false,
      },
      optionsUpcomingMultiple: {
        perPage: 3,
        gap: '2vh',
        padding: '1vh',
        rewind: true,
        pagination: false,
        drag: false,
      },
      optionsFeedSolo: {
        perPage: 3,
        gap: '2vh',
        padding: '1vh',
        rewind: true,
        arrows: false,
        pagination: false,
        drag: false,
      },
      optionsFeedMultiple: {
        perPage: 3,
        perMove: 1,
        gap: '2vh',
        padding: '1vh',
        rewind: true,
        drag: false,
      },
      optionsOfferSolo: {
        perPage: 1.3,
        gap: '2vh',
        padding: '1vh',
        rewind: true,
        arrows: false,
        pagination: false,
        drag: false,
      },
      optionsOfferMultiple: {
        perPage: 1,
        gap: '1vh',
        padding: {
          right: '40vh',
          left: '0',
        },
        rewind: true,
        pagination: false,
        drag: false,
        type: 'slide',
      },
    };
  },
  mounted() {
    this.$store.dispatch('talent/getTalentInfo');
    this.$store.dispatch('talent/getOffers');
    this.$store.dispatch('calendar/getCalendar');
    this.$store.dispatch('feed/myFeed/getPosts');
  },
  methods: {
    openProcessModal(event, offer) {
      event.stopPropagation();
      this.$modal.show(OfferProgress, {
        offer,
      }, {
        name: 'offer-process',
        width: '80%',
        height: '70vh',
        scrollable: true,
        adaptive: true,
        classes: ['overflow'],
      });
    },
    rightClick(event, offer) {
      event.stopPropagation();
      this.$modal.show(OfferProgress, {
        offer,
      }, {
        name: 'offer-process',
        width: '80%',
        height: '70vh',
        scrollable: true,
        adaptive: true,
        classes: ['overflow'],
      });
    },
    viewProcess(event) {
      if (this.$store.state.auth.type === 'Talent') {
        const openOfferModalTalent = (offer) => {
          this.$modal.show(OfferProgress, {
            offer,
          }, {
            name: 'offer-process', height: 'auto', width: '70%', classes: ['overflow'],
          });
        };

        this.$store.dispatch('talent/getOffers').then(() => {
          openOfferModalTalent(this.$store.getters['talent/offer'](event.processRef.offer));
        });
      } else if (this.$store.state.auth.type === 'Employee') {
        const openOfferModalEmployee = (applicant) => {
          this.$modal.show(ApplicantProcess,
            {
              applicant,
              processComp: ApplicantProcess,
              offerIdProp: event.processRef.offer,
            },
            {
              name: 'applicant-process',
              height: 'auto',
              scrollable: true,
              adaptive: true,
              classes: ['overflow'],
            });
        };
        if (this.$store.state.company.loadingOffers) {
          this.$store.dispatch('company/getOffers').then(() => {
            openOfferModalEmployee(this.$store.getters['company/applicant'](event.processRef.offer, event.processRef.applicant));
          });
        } else {
          this.$store.dispatch('company/getUpdatedApplication', { offer: event.processRef.offer, applicationId: event.processRef.applicant }).then(() => {
            openOfferModalEmployee(this.$store.getters['company/applicant'](event.processRef.offer, event.processRef.applicant));
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/_splide.scss";

</style>

<style lang="scss">
  .jobDash{
    .head-job{
      height: 3vw;
    }
    .inner-process{
      .step-cont{
        .step{
          height: 3vw;
          width: 3vw;
          display:flex;
          align-items: center;
          justify-content: center;
          font-size: 0;
          img{
            width: 90%;
          }
        }
      }
    }
  }
</style>
